import React from 'react';

import config from 'config';
import { InfoBar } from '@neptune/shared/venus-ui';
import { formatDate } from '@neptune/shared/common-util';
import { neptuneTrackingMetadata } from 'apis/neptune-supervisor';
import { useMaintenanceInfo } from '@neptune/maintenance-info-business-logic';

export const MaintenanceInfo: React.FC = () => {
  const info = useMaintenanceInfo(
    config.sorryAppId,
    config.sorryAppPollingTimeMs,
    config.sorryAppMaintenanceSubject,
  );
  React.useEffect(() => {
    neptuneTrackingMetadata.maintenanceInfoVisible = `${!!info}`;
  }, [info]);

  if (!info) {
    return null;
  }

  return (
    <InfoBar level="warning" dataRole="maintenance-info">
      {formatDate(info.time)} [{info.subject}] {info.description}
    </InfoBar>
  );
};
