export interface SorryAppNotice {
  id: string | number;
  type: 'unplanned' | 'planned';
  state:
    | 'scheduled' // planned
    | 'underway' // planned
    | 'complete' // planned
    | 'investigating' // unplanned
    | 'identified' // unplanned
    | 'recovering' // unplanned
    | 'resolved' // unplanned
    | 'false_alarm'; // unplanned
  timeline_state: 'future' | 'present' | 'past_recent' | 'past_distant';
  subject: string;
  synopsis: string;
  link: string;
  began_at: string | null;
  ended_at: string | null;
  begins_at?: string; // scheduled
  ends_at?: string; // scheduled
  created_at: string;
  updated_at: string;
  updates: {
    id: string | number;
    content: string;
    created_at: string;
    link: string;
    updated_at: string;
  }[];
}

export interface SorryAppStatus {
  id: string;
  name: string;
  support_email: string;
  support_telephone: string;
  support_url: string;
  created_at: string;
  updated_at: string;
  notices: SorryAppNotice[];
}

async function fetchStatus(pageId: string) {
  const baseUrl = 'https://storage.googleapis.com/neptune-status/api/status/sorryapp.json';

  // todo temporarily disabled passing "q" param because backend service caches only this ID anyway.
  // Adding it to the query breaks the cache for some reason.
  // const reqUrl = `${baseUrl}?q=${pageId}`;
  pageId;

  const reqUrl = baseUrl;

  const response = await fetch(reqUrl, {
    headers: {
      Accept: 'application/json',
    },
  });
  const json = await response.json();
  return json.response as SorryAppStatus;
}

export const sorryApp = {
  fetchStatus,
};
